<template>
  <v-container fluid>
    <v-container
      v-if="tasks && tasks.length <= 0 && !isSkeletonloader"
      fluid
      class="whiteBackGround"
    >
      <first-page
        :img-src="imgSrc"
        :dialog-name.sync="taskDialog"
        :points="points"
        :headline="headline"
      ></first-page>
    </v-container>

    <v-container
      v-if="tasks && tasks.length == 0 && isSkeletonloader"
      fluid
      class="whiteBackGround"
    >
      <v-skeleton-loader
        type="list-item-two-line, list-item-three-line, list-item-two-line, list-item-three-line, table-tfoot"
      ></v-skeleton-loader>
    </v-container>

    <v-card v-if="tasks && tasks.length > 0" class="transparent" flat>
      <v-card-title class="pt-0">
        <v-text-field
          v-model="search"
          prepend-inner-icon="search"
          clearable
          label="Search..."
          single-line
          hide-details
          solo-inverted
          flat
          @click:clear="
            search = '';
            queryV2();
          "
          @keyup.enter="queryV2"
        />
        <v-btn
          class="py-6 ml-2 mt-2 mt-sm-0"
          depressed
          color="primarygrad white--text"
          @click="queryV2"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              depressed
              class="py-6 ml-2 mt-2 mt-sm-0"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="
                taskStatus = 0;
                page = 1;
                queryV2();
              "
            >
              <v-list-item-title>ALL TASKS</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                taskStatus = 1;
                page = 1;
                queryV2();
              "
            >
              <v-list-item-title>TASKS TODO</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                taskStatus = 2;
                page = 1;
                queryV2();
              "
            >
              <v-list-item-title>TASKS DONE</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols md="4">
            <v-card>
              <v-card-title>
                Members
              </v-card-title>
              <v-card-text>
                <v-autocomplete
                  v-model="selectedAccount"
                  :items="accounts"
                  prepend-inner-icon="account_circle"
                  item-text="name"
                  item-value="id"
                  hide-details
                  solo-inverted
                  flat
                  @change="init()"
                />
                <div
                  v-if="
                    selectedAccount != 'allAccounts' &&
                      selectedAccountDetails &&
                      selectedAccountDetails.length > 0
                  "
                  class="pa-3 secondary--text text--lighten-3"
                >
                  <v-list-item-subtitle
                    >{{ selectedAccountDetails[0].role }} |
                    {{
                      selectedAccountDetails[0].username
                    }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="text-caption">{{
                    selectedAccountDetails[0].email != ""
                      ? selectedAccountDetails[0].email
                      : null
                  }}</v-list-item-subtitle>
                </div>
                <div v-if="tasks && tasks.length > 0">
                  <p class="mt-3 mb-0">
                    Task Done: {{ `${totalRecordsDone} / ${totalRecords}` }}
                  </p>
                  <v-progress-linear
                    color="light-green"
                    rounded
                    :value="Math.ceil((totalRecordsDone / totalRecords) * 100)"
                    height="15"
                  />
                </div>
              </v-card-text>
            </v-card>
            <v-card class="mt-6">
              <!-- :event-color="date => date[9] % 2 ? 'red' : 'yellow'"
              :events="functionEvents" -->
              <v-date-picker
                v-model="dates"
                range
                no-title
                full-width
                @change="queryV2()"
                show-adjacent-months
                color="light-green"
              />
            </v-card>
          </v-col>
          <v-col cols md="8">
            <v-card class="fill-height ">
              <v-card-title>
                Tasks
                <v-spacer></v-spacer>
                <v-btn depressed color="primarygrad" @click="openDialog">
                  <v-icon>add</v-icon>Add Task
                </v-btn>
              </v-card-title>
              <v-card-text v-if="loader">
                <v-skeleton-loader
                  type="list-item-two-line, list-item-two-line, list-item-two-line, table-tfoot"
                ></v-skeleton-loader>
              </v-card-text>
              <v-card-text v-else>
                <div v-if="tasks && tasks.length > 0">
                  <v-list>
                    <v-list-item
                      v-for="(task, i) in tasks"
                      :key="i"
                      two-line
                      class="px-0"
                    >
                      <v-list-item-icon
                        class="ma-0 pa-0"
                        @click="changeStatus(!task.done, task)"
                      >
                        <v-btn
                          icon
                          class="mt-3 mr-3"
                          style="padding-top: 10px;"
                        >
                          <v-icon :color="task.done ? 'light-green' : 'amber'">
                            {{
                              task.done
                                ? "mdi-check-circle-outline"
                                : "mdi-circle-outline"
                            }}</v-icon
                          >
                        </v-btn>
                      </v-list-item-icon>
                      <v-list-item-content class="pb-2">
                        <v-list-item-title>
                          <span v-if="!task.done">{{ task.name }}</span>
                          <span v-else
                            ><s>{{ task.name }}</s></span
                          >

                          <!-- <v-tooltip bottom v-if="task.contact">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon class="ml-1" v-bind="attrs" v-on="on">
                                mdi-account-outline
                              </v-icon>
                            </template>
                          </v-tooltip> -->

                          <v-chip
                            class="ma-2"
                            color="cyan"
                            label
                            text-color="white"
                            v-if="task.contact"
                          >
                            <v-icon left>
                              mdi-account-outline
                            </v-icon>
                            {{
                              task.contact.name
                                ? task.contact.name
                                : task.contact.phone
                            }}
                          </v-chip>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <!-- <span v-if="task.contact"
                            >Contact:
                            {{
                              task.contact.name
                                ? task.contact.name
                                : task.contact.phone
                            }}</span
                          > -->
                          {{ task.account.name }} |
                          {{ $moment(task.remindOn).format("D MMM hh:mm a") }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ task.desc }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="ma-0 pa-0">
                        <div class="d-flex">
                          <v-tooltip bottom v-if="!task.done">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                icon
                                @click="editItem(task, 'assign')"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon size="20">mdi-account-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Change Assignee</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="!task.done">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                icon
                                @click="editItem(task, 'time')"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon size="20">mdi-clock-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Reschedule Task</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                icon
                                @click="editItem(task)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon size="20">mdi-pencil-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Edit Task</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                icon
                                @click="deleteItem(task)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon size="20">mdi-delete-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete Task</span>
                          </v-tooltip>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <div class="d-flex">
                    <v-spacer></v-spacer>
                    <span class="mt-2"
                      >Rows<span class="d-none d-md-inline"> per page</span
                      >:</span
                    >
                    <v-select
                      v-model="records"
                      :items="recordsPerPage"
                      solo
                      hide-details
                      flat
                      dense
                      @change="pagination"
                      class="ml-1"
                      style="max-width:70px;"
                    />
                    <div class="d-flex">
                      <v-btn
                        :disabled="page == 1 ? true : false"
                        color="primary"
                        icon
                        @click="prevSet"
                      >
                        <v-icon :size="24">arrow_left</v-icon>
                      </v-btn>
                      <span class="mt-2 mx-2">
                        {{ `${page}/${pageCount}` }}
                        <span class="d-none d-md-inline">{{
                          ` of ${totalRecords}`
                        }}</span>
                      </span>
                      <v-btn
                        :disabled="page >= pageCount ? true : false"
                        color="primary"
                        icon
                        @click="nextSet"
                      >
                        <v-icon :size="24">arrow_right</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div v-else class="my-15">
                  <v-img :src="noTasks" height="150" contain /><br />
                  <h2 class="text-center">Nothing in Tasks</h2>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="taskDialog" persistent width="550">
      <v-card>
        <v-card-title class="primarygrad white--text">
          {{ formTitle }}
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0 mb-0">
          <v-text-field
            v-if="!rescheduleTask && !assignTask"
            class="my-4"
            v-model="defaultItem.name"
            label="Task Title *"
            solo
            hide-details
            dense
          />
          <v-textarea
            v-if="!rescheduleTask && !assignTask"
            class="mb-4"
            v-model="defaultItem.desc"
            rows="1"
            auto-grow
            solo
            hide-details
            dense
            label=" Task Description"
          />

          <v-expansion-panels focusable v-if="showContact">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-3 grey--text text--darken-2">
                Contact
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-autocomplete
                  v-if="showSearchOptions"
                  v-model="selectedlist"
                  label="Lists *"
                  autocomplete="off"
                  :items="lists"
                  item-text="name"
                  item-value="id"
                  class="mt-2"
                  :disabled="editedIndex > -1"
                  @change="getContactsByList"
                />

                <v-text-field
                  v-if="editedIndex > -1"
                  v-model="editedContact"
                  label="Search Contact"
                  disabled
                />

                <div v-else>
                  <v-row v-if="onSearch">
                    <v-col cols="12">
                      <v-list dense>
                        <v-list-item-group
                          v-if="contacts && contacts.length > 0"
                        >
                          <v-radio-group v-model="selectedContact">
                            <v-list-item
                              v-for="contact in contacts"
                              :key="contact.id"
                              @click="selectedContact = contact"
                            >
                              <v-radio :value="contact" return-object></v-radio>

                              <v-list-item-avatar>
                                <v-chip>
                                  <h3 v-if="contact.name" class="subtitle-2">
                                    {{ contact.name.charAt(0) }}
                                  </h3>
                                  <h3 v-else class="subtitle-2">
                                    {{ contact.phone.charAt(0) }}
                                  </h3>
                                </v-chip>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title v-if="contact.name">
                                  {{ contact.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ contact.phone }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-radio-group>
                        </v-list-item-group>
                        <v-list-item v-else>
                          <v-list-item-content class="text-center">
                            <h3 class="subtitle-2">No Contact Found</h3>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>

                  <v-text-field
                    v-model="searchContact"
                    prepend-inner-icon="mdi-magnify"
                    label="Search Name or Phone"
                    @input="onSearchChange"
                    clearable
                    clear-icon="mdi-close-circle"
                    @click:clear="clearSearch"
                  ></v-text-field>
                </div>

                <!-- <v-autocomplete
                  v-else
                  v-model="selectedContact"
                  label="Search Contact"
                  autocomplete="off"
                  :items="contacts"
                  :filter="filterContactsObject"
                >
                  <template v-slot:selection="data">
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.phone"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.phone"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete> -->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-radio-group
            v-if="!assignTask"
            v-model="dateReminder"
            row
            @change="setReminder"
            hide-details
          >
            <v-radio label="Now" value="0" />
            <v-radio label="In an hour" value="1" />
            <v-radio label="Tomorrow" value="2" />
            <v-radio label="Choose Date" value="3" />
          </v-radio-group>

          <v-card v-if="dateReminder == '3' && !assignTask" class="mt-3">
            <datetime
              v-model="defaultItem.remindOn"
              type="datetime"
              style="padding: 9px"
            ></datetime>
          </v-card>

          <div class=" d-flex" v-if="!rescheduleTask">
            <h4 class="mt-5 mb-0 mr-2 ml-1">Assign</h4>
            <v-radio-group v-model="assignOption" row @change="setSelfAssignee">
              <v-radio label="Me" value="1" />
              <v-radio label="Other" value="2" />
            </v-radio-group>
          </div>
          <v-autocomplete
            v-if="assignOption == '2' && !rescheduleTask"
            v-model="defaultItem.accountsId"
            label="Assignee *"
            autocomplete="off"
            solo
            hide-details
            :items="
              accounts.filter((d) => {
                return d.id != 'allAccounts';
              })
            "
            item-text="name"
            item-value="id"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="close">close</v-btn>
          <v-btn
            depressed
            v-if="formTitle == 'Create Task'"
            color="primarygrad white--text"
            @click="save"
            >Save</v-btn
          >
          <v-btn
            depressed
            v-if="
              formTitle == 'Edit Task' ||
                formTitle == 'Reschedule Task' ||
                formTitle == 'Reassign Task'
            "
            color="primarygrad white--text"
            @click="save"
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { Datetime } from "vue-datetime";
import fb from "../js/firebase";
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import firstPage from "@/components/firstPage.vue";

export default {
  components: {
    datetime: Datetime,
    firstPage,
  },
  data() {
    return {
      isSkeletonloader: true,
      headline: "Stay on Top of Follow-Ups with GoDial Tasks",
      points: [
        "Never Miss a Follow-Up: Create tasks directly after calls, with customizable reminders to ensure timely action on every lead or customer interaction.",
        "Boost Team Performance: Managers gain valuable insights into individual and team task completion, allowing for reassignment and improved workflow.",
        "Seamless Integration: Sync tasks with Google Calendar for a unified view of your schedule and effortless organization.",
      ],
      onSearch: false,
      searchContact: null,
      loader: false,
      editedIndex: -1,
      taskDialog: false,
      rescheduleTask: false,
      assignTask: false,
      dateReminder: "0",
      assignOption: "1",
      selected: [],
      search: "",
      headers: [
        { text: "Name", align: "left", sortable: false, value: "name" },
        { text: "Contact", align: "center", sortable: false, value: "contact" },

        {
          text: "Assigned",
          align: "left",
          sortable: false,
          value: "account.name",
        },
        // {
        //   text: "Team",
        //   align: "left",
        //   sortable: false,
        //   value: "accounts.teams.name",
        // },
        { text: "Description", align: "left", sortable: false, value: "desc" },
        { text: "Status", align: "center", sortable: false, value: "done" },
        {
          text: "Completed On",
          align: "left",
          sortable: false,
          value: "completedOn",
        },
        {
          text: "Created On",
          align: "left",
          sortable: false,
          value: "createdOn",
        },
        { text: "Action", align: "center", sortable: false, value: "action" },
      ],
      tasks: [],
      defaultItem: {
        companyId: "",
        accountsId: "",
        name: "",
        reminder: true,
        action: "",
        contactId: "",
        remindOn: this.$moment().format(),
        desc: "",
        done: false,
      },
      accountsForSelection: [],
      selectedAccount: "",
      selectedAccountDetails: [],
      accounts: [],
      // Pagination
      recordsPerPage: [5, 15, 30, 60, 120],
      page: 1,
      pageCount: 0,
      totalRecords: 0,
      totalRecordsDone: 0,
      records: 5,
      lists: [],
      selectedlist: {},
      contacts: [],
      selectedContact: {},
      editedContact: {},
      showSearchOptions: true,
      modal: false,
      dates: [],
      taskStatus: 0,
      showTableCtrl: false,
    };
  },
  computed: {
    ...mapGetters(["selectedTeam", "ENDPOINT", "teams", "user"]),
    imgSrc() {
      return require("../assets/gotask.svg");
    },
    noTasks() {
      return require("../assets/no-task.png");
    },
    formTitle() {
      if (this.editedIndex === -1) return "Create Task";
      else {
        if (this.rescheduleTask) return "Reschedule Task";
        else if (this.assignTask) return "Reassign Task";
        else return "Edit Task";
      }
    },
    paginate() {
      return this.$store.getters.paginate;
    },
    dateRange() {
      return this.dates.join(" - ");
    },
    showContact() {
      if (
        !this.rescheduleTask &&
        !this.assignTaskthis &&
        this.showSearchOptions
      )
        return true;
      return false;
    },
  },
  watch: {
    selectedTeam() {
      this.selectedAccount = "";
      this.init();
    },
  },
  async mounted() {
    try {
      // Paginate
      var routeName = this.$router.currentRoute.name
        .toString()
        .trim()
        .replace(/ /g, "")
        .toLowerCase();
      if (this.paginate[routeName]) {
        this.page = this.paginate[routeName].page;
        (this.pageCount = this.paginate[routeName].pageCount),
          (this.totalRecords = this.paginate[routeName].totalRecords),
          (this.records = this.paginate[routeName].records);
      }
      await this.init();
    } catch (e) {
      console.log(e);
    }
  },
  created() {
    //this.init();
  },
  methods: {
    isMobile() {
      if (window.innerWidth <= 760) {
        return true;
      } else {
        return false;
      }
    },
    contactSelection(contact) {
      this.selectedContact = contact;
      // this.handleNewChat();
    },
    clearSearch() {
      this.onSearch = false;
    },
    async onSearchChange() {
      if (!this.selectedlist || this.selectedlist == "") {
        this.$swal({
          type: "error",
          text:
            "Please select a list from list dropdown before search contact.",
        });

        return;
      }

      console.log("this.searchContact", this.searchContact);

      if (this.searchContact && this.searchContact.length > 2) {
        try {
          await this.$http
            .post(
              `${this.$store.state.ENDPOINT}/contacts/query-contact?v2=true`,
              {
                listId: this.selectedlist,
                search: this.searchContact,
              }
            )
            .then((response) => {
              this.onSearch = true;
              this.contacts = response.data;
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (err) {
          console.log(err);
        }
      }
    },
    filterContactsObject(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.phone.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
    async getContactsByList() {
      try {
        var response = await this.$http.get(
          `${this.ENDPOINT}/lists/${this.selectedlist}/contacts`
        );
        this.contacts = response.body;
      } catch (e) {
        console.log(e);
      }
    },
    /** SEARCH */
    async query() {
      try {
        if (this.search != "") {
          this.page = 1;
          //init
          this.fetchCount();
          await this.pagination();
        } else {
          //if(this.selectedFilterOptions.includes())
          this.fetchCount();
          this.tasks = await this.fetchTasks(
            this.records,
            this.records * this.page - this.records
          );
        }
      } catch (e) {
        this.loader = false;
      }
    },
    async queryV2() {
      try {
        if (this.search != "") {
          this.page = 1;
          //init
          this.fetchCountV2();
          await this.pagination();
        } else {
          //if(this.selectedFilterOptions.includes())
          this.fetchCountV2();
          this.tasks = await this.fetchTasksV2(
            this.records,
            this.records * this.page - this.records
          );
        }
      } catch (e) {
        console.log("Error", e);
      }
    },

    batchDelete() {
      if (this.selected && this.selected.length < 1)
        return this.$swal({
          type: "warning",
          text: "Nothing in selected Tasks",
        });
      this.loader = true;

      this.$http
        .post(`${this.$store.state.ENDPOINT}/tasks/bulk/delete`, {
          ids: this.selected,
        })
        .then((response) => {
          this.$swal({ type: "success", text: " Tasks deleted successfully" });
          this.init();
          this.selected = [];
        })
        .catch((error) => {
          this.loader = false;
          this.$swal({ type: "error", text: "Something went wrong" });
        });
    },
    async changeStatus(done, task) {
      try {
        var payload = new Object();
        payload.done = done;
        payload.modifiedOn = this.$moment().format();
        if (payload.done) payload.completedOn = this.$moment().format();
        // else payload.completedOn = "";

        await this.$http.patch(
          `${this.$store.state.ENDPOINT}/tasks/${task._id}`,
          payload
        );

        this.$swal({
          type: "success",
          text: `Marked as ${done ? "complete." : "incomplete."}`,
        });
        this.fetchTasksV2();
      } catch (e) {
        this.$swal({ type: "error", text: "Something went wrong" });
      }
    },
    async init() {
      this.selectedAccountDetails = this.accounts.filter((a) => {
        return a.id == this.selectedAccount;
      });

      try {
        await this.fetchListByTeam();
        //fetch accounts
        await this.fetchAccounts();
        //fetch tasks
        await this.fetchCountV2();
        if (this.page < 1 || this.page > this.pageCount) {
          this.page = 1;
        }
        await this.fetchTasksV2(
          this.records,
          this.records * this.page - this.records
        );
      } catch (e) {
        this.loader = false;
      }
    },
    async fetchListByTeam() {
      const self = this;
      try {
        var teamsId = self.getTeamIds();
        var response = await self.$http.post(`${self.ENDPOINT}/lists/fetch`, {
          teams: teamsId,
        });
        self.lists = response.body;
      } catch (e) {
        console.log(e);
      }
    },

    /**PAGINATION */
    nextSet() {
      this.page++;
      this.pagination();
    },
    prevSet() {
      this.page--;
      this.pagination();
    },
    async pagination() {
      try {
        this.pageCount = Math.ceil(this.totalRecords / this.records);
        if (this.page > this.pageCount) {
          this.page = 1; //set default
        }
        this.tasks = await this.fetchTasksV2(
          this.records,
          this.records * this.page - this.records
        );

        // Save default pagination of seperate pages
        this.paginate[
          this.$router.currentRoute.name
            .toString()
            .trim()
            .replace(/ /g, "")
            .toLowerCase()
        ] = {
          page: this.page,
          pageCount: this.pageCount,
          records: this.records,
          totalRecords: this.totalRecords,
        };
        this.$store.commit("setPagination", this.paginate);
        return;
      } catch (e) {
        console.log(e);
        this.loader = false;
      }
    },

    fetchTasksV2(limit, skip) {
      this.tasks = [];
      this.loader = true;
      // Accounts array
      var accounts = [];
      if (this.selectedAccount == "allAccounts") {
        accounts = _.map(this.accounts, "id");
      } else {
        accounts.push(this.selectedAccount);
      }
      accounts = _.without(accounts, "allAccounts"); // remove the allaccounts id

      if (limit == undefined || skip == undefined) {
        limit = 5;
        skip = 0;
      }

      // search
      var search = "";

      if (typeof this.search != "undefined" && this.search != undefined) {
        search = this.search;
      }

      var taskStage = "all";
      if (this.taskStatus == 1) taskStage = "todo";
      else if (this.taskStatus == 2) taskStage = "done";

      if (this.dates && this.dates.length < 1) {
        this.dates = [
          moment()
            .subtract(7, "days")
            .format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ];
      }

      this.$http
        .post(`${this.$store.state.ENDPOINT}/tasks/byFilterV2`, {
          queryCase: "data",
          accounts: accounts,
          search: search,
          taskStage: taskStage,
          dateRange: this.dates,
          limit: limit,
          skip: skip,
        })
        .then((response) => {
          this.loader = false;
          response = response.body;
          this.isSkeletonloader = false;
          this.tasks = _.map(response, (d) => {
            d.createdOn = this.$moment(d.createdOn).format(
              "Do MMMM YYYY, h:mm A"
            );
            if (d.completedOn && d.completedOn != "") {
              d.completedOn = this.$moment(d.completedOn).format(
                "Do MMMM YYYY, h:mm A"
              );
            }
            return d;
          });

          this.showTableCtrl = false;
          if (response && response.length > 0) {
            this.showTableCtrl = true;
          }
        })
        .catch((e) => {
          this.loader = false;
          this.$swal({ type: "error", text: "Error while fetching tasks" });
        });
    },

    async fetchCountV2() {
      try {
        var accounts = [];

        if (this.selectedAccount == "allAccounts") {
          accounts = _.map(this.accounts, "id");
        } else {
          accounts.push(this.selectedAccount);
        }
        accounts = _.without(accounts, "allAccounts"); // remove the allaccounts id

        // search
        var search = "";

        if (typeof this.search != "undefined" && this.search != undefined) {
          search = this.search;
        }

        var taskStage = "all";

        if (this.taskStatus == 1) taskStage = "todo";
        else if (this.taskStatus == 2) taskStage = "done";

        if (this.dates && this.dates.length < 1) {
          this.dates = [
            moment()
              .subtract(7, "days")
              .format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
          ];
        }

        var response = await this.$http.post(
          this.$store.state.ENDPOINT + "/tasks/byFilterV2",
          {
            queryCase: "count",
            taskStage: taskStage,
            accounts: accounts,
            search: search,
            dateRange: this.dates,
          }
        );
        this.totalRecords = response.body[0].count;
        this.pageCount = Math.ceil(this.totalRecords / this.records);
      } catch (e) {
        console.log("Error", e);
      }
      if (this.taskStatus == 0) {
        try {
          var responseDone = await this.$http.post(
            this.$store.state.ENDPOINT + "/tasks/byFilterV2",
            {
              queryCase: "count",
              taskStage: "done",
              accounts: accounts,
              search: search,
              dateRange: this.dates,
            }
          );
          this.totalRecordsDone = responseDone.body[0].count;
          // console.log("this.totalRecordsDone: ",this.totalRecordsDone);
        } catch (e) {
          console.log("Error in getting done tasks", e);
        }
      } else if (this.taskStatus == 1) {
        this.totalRecordsDone = 0;
      } else {
        this.totalRecordsDone = this.totalRecords;
      }
    },

    /** recieve teams array of ids */
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }
      teams = _.without(teams, "allTeams");
      return teams;
    },
    /** Fetch the accounts based on teamsId */
    async fetchAccounts() {
      this.loader = true;
      this.accounts = [];
      // Teams sections
      var teams = this.getTeamIds();

      try {
        var response = await this.$http.post(
          `${this.$store.state.ENDPOINT}/accounts/fetch`,
          {
            teams: teams,
          }
        );
        response = response.body;
        this.accounts = response;
        if (
          this.$store.getters.user.role == "Manager" ||
          this.$store.getters.user.role == "Teammanager"
        ) {
          this.accounts.unshift({ id: "allAccounts", name: "All Members" });
        }
        if (
          this.selectedAccount == "" &&
          this.accounts &&
          this.accounts.length > 0
        ) {
          return (this.selectedAccount = this.accounts[0].id);
        }
      } catch (e) {
        this.loader = false;
        this.$swal({ type: "error", text: "Something went wrong" });
      }
    },

    save() {
      if (this.defaultItem.accountsId == "")
        return this.$swal({ type: "warning", text: "Please enter a Assignee" });
      if (this.defaultItem.name == "")
        return this.$swal({
          type: "warning",
          text: "Please enter a Task Name",
        });
      //company id
      this.defaultItem.companyId = this.$store.getters.user.companyId;

      // if (this.defaultItem.reminder) {
      //   if (this.defaultItem.remindOn == "") {
      //     return this.$swal({ type: "warning", text: "Blank Date field" });
      //   }
      // } else {
      //   //If no reminder date set it for today
      //   this.defaultItem.remindOn = this.$moment().format();
      // }
      this.defaultItem.action = "task";

      this.loader = true; //init loader

      if (typeof this.selectedContact == "object") {
        this.defaultItem.contactId = this.selectedContact._id;
      }

      if (this.editedIndex > -1) {
        var jsonObj = {
          accountsId: this.defaultItem.accountsId,
          name: this.defaultItem.name,
          reminder: this.defaultItem.reminder,
          action: this.defaultItem.action,
          contactId: this.defaultItem.contactId,
          remindOn: this.defaultItem.remindOn,
          desc: this.defaultItem.desc,
          done: this.defaultItem.done,
          modifiedOn: this.$moment().format(),
        };

        if (this.defaultItem.done == true) {
          jsonObj.completedOn = this.defaultItem.completedOn;
        }

        if (this.defaultItem.callItem) {
          jsonObj.callItem = this.defaultItem.callItem;
        }

        this.$http
          .patch(
            `${this.$store.state.ENDPOINT}/tasks/${this.defaultItem._id}`,
            jsonObj
          )
          .then((response) => {
            this.onSearch = false;
            this.searchContact = null;
            this.init();
            fb.log("task_update");
            this.taskDialog = false;

            this.$swal({
              type: "success",
              text: `${this.defaultItem.name} successfully updated`,
            });
            this.defaultItem = {
              companyId: "",
              accountsId: "",
              name: "",
              reminder: true,
              action: "task",
              remindOn: this.$moment().format(),
              desc: "",
              done: false,
            };
          })
          .catch((err) => {
            this.loader = false;
            this.onSearch = false;
            this.searchContact = null;
            this.$swal({
              type: "success",
              text: `error while updating ${this.defaultItem.name}. try again later`,
            });
          });
      } else {
        this.$http
          .post(`${this.$store.state.ENDPOINT}/tasks`, this.defaultItem)
          .then((response) => {
            this.onSearch = false;
            this.searchContact = null;
            this.init();
            this.taskDialog = false;

            this.$swal({
              type: "success",
              text: `${this.defaultItem.name} successfully added`,
            });
            this.defaultItem = {
              companyId: "",
              accountsId: "",
              name: "",
              reminder: true,
              action: "task",
              contactId: "",
              remindOn: this.$moment().format(),
              desc: "",
              done: false,
            };
            fb.log("task_add");
          })
          .catch((err) => {
            console.log("err", err);
            this.loader = false;
            this.onSearch = false;
            this.searchContact = null;
            this.$swal({
              type: "success",
              text: "error while creating task. try again later",
            });
          });
      }
    },

    openDialog() {
      this.rescheduleTask = false;
      this.assignTask = false;
      this.onSearch = false;
      this.searchContact = null;
      this.editedIndex = -1;
      this.defaultItem = {
        companyId: "",
        accountsId: "",
        name: "",
        reminder: true,
        action: "",
        contactId: "",
        remindOn: this.$moment().format(),
        desc: "",
        done: false,
      };
      this.showSearchOptions = true;
      this.selectedContact = {};
      this.selectedlist = this.lists[0].id;

      // this.getContactsByList();
      this.defaultItem.accountsId = this.accounts[1].id;
      this.taskDialog = true;
    },
    editItem(task, role) {
      this.dateReminder = "3";
      this.assignOption = "2";
      this.a;
      if (role == "time") {
        this.assignTask = false;
        this.rescheduleTask = true;
      } else if (role == "assign") {
        this.rescheduleTask = false;
        this.assignTask = true;
      } else {
        this.rescheduleTask = false;
        this.assignTask = false;
      }
      this.editedIndex = this.tasks.indexOf(task);
      this.defaultItem = Object.assign({}, task);
      if (typeof task.contact != "undefined") {
        // Check for Contact Object
        this.showSearchOptions = true;
        this.selectedlist = task.contact.listId;
        this.selectedContact = task.contact;
        this.editedContact = `${task.contact.name} (${task.contact.phone})`;
      } else if (typeof task.callItem != "undefined") {
        // Check for CallItem Object
        this.showSearchOptions = true;
        this.selectedlist = task.callItem.listId;
        this.selectedContact = task.callItem;
        this.editedContact = `${task.callItem.name} (${task.callItem.phone})`;
      } else {
        this.showSearchOptions = false;
      }
      this.taskDialog = true;
    },
    deleteItem(task) {
      this.$swal({
        title: "Are you sure?",
        text: "You are deleting Tasks?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1bca3f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((willDelete) => {
        if (willDelete.value) {
          this.loader = true;
          this.$http
            .delete(`${this.$store.state.ENDPOINT}/tasks/${task._id}`)
            .then((response) => {
              this.init();
              fb.log("task_delete");
            })
            .catch((e) => {
              this.loader = false;
              this.$swal({
                type: "error",
                text: `error while deleting ${task.name}`,
              });
            });
        }
      });
    },
    close(role) {
      this.taskDialog = false;
      this.showSearchOptions = true;
      this.selectedlist = "";
      this.searchContact = false;
      this.onSearch = false;

      this.defaultItem = {
        companyId: "",
        accountsId: "",
        name: "",
        reminder: true,
        action: "",
        contactId: "",
        remindOn: this.$moment().format(),
        desc: "",
        done: false,
      };
    },
    setReminder() {
      if (this.dateReminder == "1") {
        this.defaultItem.remindOn = new Date().toISOString();
      }
      if (this.dateReminder == "1") {
        this.defaultItem.remindOn = new Date(
          moment().add(1, "hour")
        ).toISOString();
      } else if (this.dateReminder == "2") {
        this.defaultItem.remindOn = new Date(
          moment().add(1, "days")
        ).toISOString();
      }
      // console.log("dateReminder after:",this.defaultItem.remindOn);
    },
    setSelfAssignee() {
      if (this.assignOption == "1") {
        this.defaultItem.accountsId = this.user.id;
      }
    },
  },
};
</script>
<style>
.vdatetime-popup__header,
.vdatetime-calendar__month__day--selected > span > span {
  background: linear-gradient(to right, #92df48, #1bca3f);
}
.vdatetime-calendar__month__day--selected:hover > span > span {
  background: linear-gradient(to top, #92df48, #1bca3f);
}
.vdatetime-time-picker__item--selected {
  color: #13a130;
}
.vdatetime-popup__actions__button--cancel {
  color: rgb(255, 61, 61);
  font-weight: 500;
}
.vdatetime-popup__actions__button--confirm {
  background: linear-gradient(to top, #92df48, #1bca3f);
  border-radius: 5px;
  color: white;
  font-weight: 700;
}

.cs-box {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.image-container img {
  width: auto;
  /* Change this to auto to maintain aspect ratio */
  max-width: 100%;
  /* Prevents the image from exceeding the container width */
  height: auto;
}

.text-transform {
  text-transform: capitalize;
}

.whiteBackGround {
  background-color: white;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-margin {
  margin: 0;
  /* Override any default margin */
}

@media (max-width: 760px) {
  .image-container {
    margin-bottom: 20px;
    /* Adds spacing between the image and text content on mobile */
  }
}

.customRadiusDialog {
  border-radius: 0px !important;
}

.v-application .color-avatar {
  cursor: pointer;
  border: 4px solid transparent;
  /* Maintains layout consistency */
  border-radius: 50%;
  /* Ensures the avatar is round */
  transition: border-color 0.3s;
}

.v-application .active-border {
  border-color: rgb(188 188 188) !important;
  /* Overrides Vuetify theme color */
}

.v-card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.v-simple-table td {
  vertical-align: middle;
}

.v-img {
  max-width: 50px;
  height: auto;
  display: inline-block;
}

.green-row {
  background-color: #cffede;
  /* Light green color */
}
</style>
